import React, { useContext } from 'react';
import { LanguageConsumer } from "../contexts/LanguageContext";
import LanguageContext from "../contexts/LanguageContext";

import "./Legal.scss";

import Layout from "../Components/Layout";

export default function Legal() {

    const context = useContext(LanguageContext);
    let tabTitle;
    
    context.activeLang === "en" ? tabTitle = "Legal Information" : tabTitle = "Impressum"
    document.title = tabTitle + " | Via Instruments"

    return (
        <Layout>
            <LanguageConsumer>
                {value =>   
                    <>
                        <div className="Page__Banner Banner__Legal"></div>
                        <div className="Page__Content Page__Legal">
                            <h1>{value.page.heading.legal}</h1>
                            <h4>VIA Instruments GmbH</h4>
                            <ul>
                                <li>Bergdahlsweg 9</li>
                                <li>D-47509 Rheurdt – Germany</li>
                                <li>Fon (Fax): +49 2833 5728-32 (33)</li>
                                <li>Mobile: +49 172 7073283</li>
                            </ul>
                            <h4>Geschäftsführer/Managing Director:</h4>
                            <p>Vito Angona</p>
                            <h4>Sitz/Registered office: </h4>
                            <p>Rheurdt – Handelregister/ Company register Kleve: HRB 13294</p>
                            <h4>St-Nr./Tax no.: </h4>
                            <p>113/5765/1560</p>
                        </div>
                    </>
                
                }
            </LanguageConsumer>
        </Layout>
    )
}
 