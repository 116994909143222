import React, { useContext } from 'react';
import { LanguageConsumer } from "../contexts/LanguageContext";
import LanguageContext from "../contexts/LanguageContext";

import "./Home.scss";

import Layout from "../Components/Layout";

export default function Home() {

    const context = useContext(LanguageContext);
    let tabTitle;
    
    context.activeLang === "en" ? tabTitle = "Company" : tabTitle = "Unternehmen"
    document.title = tabTitle + " | Via Instruments"

    return (
        <Layout>
            <LanguageConsumer>
                {value =>   
                    <>
                        <div className="Page__Banner Banner__Home">
                            <div className="Banner__Home-1"></div>
                            <div className="Banner__Home-2"></div>
                            <div className="Banner__Home-3"></div>
                        </div>
                        <div className="Page__Content Page__Home">
                            <h1>{value.page.heading.home}</h1>
                            <p>{value.page.content.home}</p>
                        </div>
                    </>
                
                }
            </LanguageConsumer>
        </Layout>
    )
}
