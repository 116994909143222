import React, {useContext, useEffect, useState} from 'react';
import {LanguageProvider} from "../contexts/LanguageContext";
import LanguageContext from "../contexts/LanguageContext";

import "./Layout.scss";

import ScrollUpButton from "react-scroll-up-button";
import CookieConsent from "react-cookie-consent";  

import history from "../history";
import Nav from "./Nav";
import Footer from "./Footer";

import languages from "../Languages/languages.json";

export default function Layout(props) {

    const context = useContext(LanguageContext);
    const [pageLoaded, setPageLoaded] = useState(false)
    const toggleLanguage = () => {
        if (context.activeLang === "en") {
            context.activeLang = "de";
            history.push("/")
        } else if (context.activeLang === "de") {
            context.activeLang = "en";
            history.push("/")
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true)
        }, 10)
    })

    const langStrings = () => context.activeLang === "en" ? languages.en : languages.de

    return (
        <LanguageProvider value={langStrings()}>

            <div className="Layout  ">
                <Nav>
                    <img    className="Nav__Lang-Icon" 
                            src="globe-darkgrey.svg" 
                            alt="globe.svg"
                            onClick={toggleLanguage}/>
                </Nav> 
                <div className={`Page ${pageLoaded && "Page-IsVisible"}`}>{props.children}</div>
                <Footer className="Footer" />
                <div className="BottomBar">© 2021 VIA Instruments GmbH</div>
            </div>

            <CookieConsent  buttonText={langStrings().basics.cookie.btn}
                            style={{ background: "#2B373B" }} 
                            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}>
                            {langStrings().basics.cookie.msg}
            </CookieConsent>
            <ScrollUpButton style={{borderRadius: "50px",outline: "none", padding: "5px"}} />
        </LanguageProvider>
    )
}