import React from 'react';
import {LanguageConsumer} from "../contexts/LanguageContext";

import "./Footer.scss";

import {NavLink} from "react-router-dom";

export default function Footer() {

    return (
        <LanguageConsumer>
            {value => 
                <>
                    <div className="Footer">
                        <div>
                            <div className="Footer__Links">
                                <NavLink exact to="/"               >{value.header.nav.home}</NavLink>
                                <NavLink exact to="/consulting"     >{value.header.nav.services[2]}</NavLink>
                                <NavLink exact to="/participation"  >{value.header.nav.services[1]}</NavLink>
                                <NavLink exact to="/partners"       >{value.header.nav.partners}</NavLink>
                                <NavLink exact to="/contact"        >{value.header.nav.contact}</NavLink>
                                <NavLink exact to="/legal"          >{value.header.nav.legal}</NavLink>
                            </div>
                            <div className="Footer__Sozial">
                                <a href="https://www.easymeter.com/">EasyMeter GmbH</a>
                                <a href="https://www.hne.ag/">HNE AG</a>
                                <a href="https://www.nedtax.de/">NedTax</a>
                                <a href="https://www.scs-beratung.com/">SCS Beratung</a>
                                <a href="https://www.sciaps.com/">SciAps</a>
                            </div>

                        </div>
                    </div>
                </>            
            }
        </LanguageConsumer>
    )
}
