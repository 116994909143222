import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import './App.css';
import Home from "./Pages/Home";
// import Services from "./Pages/Services";
import Consulting from "./Pages/Consulting";
import Participation from "./Pages/Participation";
import Partners from "./Pages/Partners";
import Contact from "./Pages/Contact";
import Legal from "./Pages/Legal";

class App extends Component {
  render(){
    return(
      <>
          <Route exact path="/" render = {(routeProps)=> <Home {...routeProps}/>}/>
          {/* <Route exact path="/services" render = {(routeProps)=> <Services {...routeProps}/>}/> */}
          <Route exact path="/partners" render = {(routeProps)=> <Partners {...routeProps}/>}/>
          <Route exact path="/consulting" render = {(routeProps)=> <Consulting {...routeProps}/>}/>         
          <Route exact path="/participation" render = {(routeProps)=> <Participation {...routeProps}/>}/>               
          <Route exact path="/contact" render = {(routeProps)=> <Contact {...routeProps}/>}/>  
          <Route exact path="/legal" render = {(routeProps)=> <Legal {...routeProps}/>}/>        
      </>
    )
  }
}
export default App;
