import React, { useContext } from 'react';
import { LanguageConsumer } from "../contexts/LanguageContext";
import LanguageContext from "../contexts/LanguageContext";

import "./Partners.scss";

import Layout from "../Components/Layout";

export default function Partners() {

    const context = useContext(LanguageContext);
    let tabTitle;
    
    context.activeLang === "en" ? tabTitle = "Partners" : tabTitle = "Partner"
    document.title = tabTitle + " | Via Instruments"

    return (
        <Layout>
            <LanguageConsumer>
                {value =>   
                    <>
                        <div className="Page__Banner Banner__Partners"></div>
                        <div className="Page__Content">
                            <h1>{value.page.heading.partners}</h1>
                            <div className="Logos-Container">
                                {value.page.content.partners.map((product, i) => 
                                    <div key={i} className="Logos-Item">
                                        <a href={product[2]}><img src={product[1]} alt="company-logo" /></a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                
                }
            </LanguageConsumer>
        </Layout>
    )
}
 