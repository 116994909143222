import React, { useState } from 'react';

import { HamburgerSpin } from 'react-animated-burgers';

import "./BurgerMenu.scss";

export default function BurgerMenu() {

  const [isBurgerActive, setIsBurgerActive] = useState(false)
  const backgroundColor = "rgb(250, 250, 250)";
  const barColor = "rgb(58, 58, 58)";

  const toggleButton = () => {
    setIsBurgerActive(!isBurgerActive)
  }

  return (
      <HamburgerSpin  isActive={isBurgerActive} 
                      toggleButton={toggleButton}
                      buttonColor={backgroundColor}
                      buttonWidth={30} 
                      barColor={barColor}/>
  )
}
