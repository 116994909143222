import React, {useState} from 'react';
import { LanguageConsumer } from "../contexts/LanguageContext";

import "./ContactFormNew.scss";

import emailjs from 'emailjs-com';
import SyncLoader from "react-spinners/SyncLoader";
// https://www.npmjs.com/package/react-spinners || Change name according to spinner

export default function ContactFormNew(props) {
    
    // STATE
    const [name, setName] = useState("");
    const [adress, setAdress] = useState("");
    const [message, setMessage] = useState("");
    const [notificationColor, setNotificationColor] = useState("");
    const [notificationMsg, setNotificationMsg] = useState("");
    const [loading, setLoading] = useState(false);
    
    // FUNCTIONS
    const handleFormReset = () => {
        setName("");
        setAdress("");
        setMessage("")
    }

    const handleNotificationDisplay = (msg, color) => {
        setNotificationMsg(msg);
        setNotificationColor(color);
        setTimeout(() => {
            setNotificationMsg("");
            setNotificationColor("");
        }, 3000)
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const templateParams = {
            from_name: name,
            from_email: adress,
            to_name: 'info@via-instruments.com',
            message_html: message
        }

        if (message !== "" && adress !== "" && name !== "") {
            setLoading(true);
            emailjs.send(
                'one_com',
                'info_via_instruments_com',
                templateParams,
                'user_Ftk0wTZt8WGlCtMt70cHJ'
            )
            .then(res => {
                setLoading(false);
                handleNotificationDisplay("E-mail sucessfully sent!", "#2E8B57");
                handleFormReset();        
            })
            .catch(err => {
                setLoading(false);
                handleNotificationDisplay("Failed sending E-mail!", "#DC143C");
                handleFormReset();
            })
        } else {
            handleNotificationDisplay("All fields must be filled!", "#DC143C")
        }
    }

    // MISC

    const override = `display: block; margin: 0 auto;`;
    const infoMsg = (info, color) => {
        return (<p className="Styles__message" style={{color}}>{info}</p>)
    }

    return (
        
        <LanguageConsumer>
            {value =>   
                <>
                    <div className="ContactForm">
                        <div className="ContactForm__Notification">
                            {loading === true
                            ?
                            <SyncLoader
                                css={override}
                                size={10}
                                color={"#5891a1"}
                                loading={loading}/>
                            :
                            infoMsg(notificationMsg, notificationColor)}
                        </div>
                    
                        <form className="ContactForm__Form">
                            <div className="ContactForm__Form__Inputs">
                                <input 
                                    id="name"
                                    name="name"
                                    className="ContactForm__Form__Name"
                                    placeholder={value.page.content.contact.form.name}
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    required />
                                <input 
                                    id="adress"
                                    name="adress"
                                    className="ContactForm__Form__Adress"
                                    placeholder={value.page.content.contact.form.email}
                                    onChange={e => setAdress(e.target.value)}
                                    value={adress}
                                    required />
                            </div>
                            <textarea
                                id="message"
                                name="message"
                                className="ContactForm__Form__Message"
                                placeholder={value.page.content.contact.form.msg}
                                onChange={e => setMessage(e.target.value)}
                                value={message}
                                required />
                            <input 
                                type="button" 
                                value={value.page.content.contact.form.btn}
                                className="ContactForm__Form__SubmitBtn" 
                                onClick={handleFormSubmit} />
                        </form>
                    </div>
                </>
            }
        </LanguageConsumer>
    )
}
