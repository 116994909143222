import React, { useContext } from 'react';
import { LanguageConsumer } from "../contexts/LanguageContext";
import LanguageContext from "../contexts/LanguageContext";

import "./Contacts.scss"; 

import Layout from "../Components/Layout";
import ContactFormNew from "../Components/ContactFormNew";

export default function Contact() {

    const context = useContext(LanguageContext);
    let tabTitle;
    
    context.activeLang === "en" ? tabTitle = "Contact" : tabTitle = "Kontakt"
    document.title = tabTitle + " | Via Instruments"


    return (
        <Layout>
            <LanguageConsumer>
                {value =>  
                    <>
                        <div className="Page__Banner Banner__Contact"></div>
                        <div className="Page__Contact Page__Content">
                            <h1>{value.page.heading.contact}</h1>
                            <ContactFormNew />
                            <div className="Page__Contact__Info">
                                <p>Tel : +49(0)2833 / 572832</p>
                                <p>Mail: info@via-instruments.com</p>
                            </div>

                        </div> 
                    </>
                }
            </LanguageConsumer>
            
        </Layout>
    )
}
