import React, { useState } from 'react';
import {LanguageConsumer} from "../contexts/LanguageContext";
import {NavLink} from 'react-router-dom';

import "./Nav.scss";

import BurgerMenu from "./BurgerMenu";

export default function Nav(props) {

    const navSelected = "rgb(90, 119, 176)";
    const [burgerIsActive, setBurgerIsActive] = useState(false);

    const toggleBurgerMenu = () => {
        setBurgerIsActive(!burgerIsActive);
    }

    return (
        <LanguageConsumer>
            {value =>
            <>
                <div className="Nav">
                    <NavLink exact to="/" activeStyle={{color: navSelected}} className="Nav__Logo"><img src="/logo.png" alt="logo"></img></NavLink>
                    <div    className="Nav__Links">
                    <NavLink    exact to="/"
                                activeStyle={{color: navSelected}} >{value.header.nav.home}</NavLink>
                    <NavLink    exact to="/consulting"
                                activeStyle={{color: navSelected}} >{value.header.nav.services[2]}</NavLink>
                    <NavLink    exact to="/participation"
                                activeStyle={{color: navSelected}} >{value.header.nav.services[1]}</NavLink>                                                                
                    {/* <NavLink    exact to="/services" 
                                activeStyle={{color: navSelected}} >{value.header.nav.services}</NavLink> */}
                    <NavLink    exact to="/partners" 
                                activeStyle={{color: navSelected}} >{value.header.nav.partners}</NavLink>
                    <NavLink    exact to="/contact"  
                                activeStyle={{color: navSelected}} >{value.header.nav.contact}</NavLink>
                    </div>
                    
                    <div>
                        <div className="Nav__Lang">
                            {props.children}
                            <span className="Nav__Lang-Id">{value.basics.id}</span>
                        </div>
                        <div onClick={toggleBurgerMenu} className="Nav__BurgerMenu-Icon">
                            <BurgerMenu />
                        </div>
                    </div>


                </div>
                <div className={burgerIsActive ? "Burger__Box Burger__Box--active" : "Burger__Box"}>
                    <NavLink    exact to="/"
                                activeStyle={{color: navSelected}} >{value.header.nav.home}</NavLink>
                    <NavLink    exact to="/consulting"
                                activeStyle={{color: navSelected}} >{value.header.nav.services[2]}</NavLink>
                    <NavLink    exact to="/participation"
                                activeStyle={{color: navSelected}} >{value.header.nav.services[1]}</NavLink>                                                                
                    {/* <NavLink    exact to="/services" 
                                activeStyle={{color: navSelected}} >{value.header.nav.services}</NavLink> */}
                    <NavLink    exact to="/partners" 
                                activeStyle={{color: navSelected}} >{value.header.nav.partners}</NavLink>
                    <NavLink    exact to="/contact"  
                                activeStyle={{color: navSelected}} >{value.header.nav.contact}</NavLink>
                </div>   
            </>
            }
            
        </LanguageConsumer>
    )
}
