import React, { useContext } from 'react';
import { LanguageConsumer } from "../contexts/LanguageContext";
import LanguageContext from "../contexts/LanguageContext";

import "./Consulting.scss"; 

import Layout from "../Components/Layout";

export default function Consulting() {

    const context = useContext(LanguageContext);
    let tabTitle;
    
    context.activeLang === "en" ? tabTitle = "Consultancy" : tabTitle = "Beratung"
    document.title = tabTitle + " | Via Instruments"

    return (
        <Layout>
            <LanguageConsumer>
                {value =>   
                    <>
                    <div className="Page__Banner Banner__Consulting"></div>
                    <div className="Page__Content Page__Consulting">
                    <h1>{value.page.heading.services[2]}</h1>                         
                        <p>{value.page.content.services.consulting.txt[0]}</p>
                        <div className="List-Container">
                            {value.page.content.services.consulting.products.map((product, i) => 
                                <p className="List-Item" key={i}>{product}</p>
                            )}
                        </div>
                        <p>{value.page.content.services.consulting.txt[1]}</p>
                    </div>
                    </>
                }
            </LanguageConsumer>
        </Layout>
    )
}
