import React from "react";

const LanguageContext = React.createContext({
    activeLang: "de",
    pageLoaded: false
});

export const LanguageProvider = LanguageContext.Provider;
export const LanguageConsumer = LanguageContext.Consumer

export default LanguageContext;